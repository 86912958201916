import { ViewsType } from '~/types/ViewTypes'
import useViewRouter from '~/hooks/useViewRouter'

type Props = {
    view: ViewsType,
    width?: string, 
    title: string, 
    subtitle: string, 
    icon?: string
}

const MenuItem = (
    {
        view,
        width = '33.3%', 
        title, 
        subtitle, 
        icon
    }: Props) => {
    const viewRouter = useViewRouter()
    const handleClick = () => {
        viewRouter.push(view)
    } 

    return(
        <div className="menu-item" style={{width}} onClick={handleClick}>
            <div className="menu-item__container">
                <div className="menu-item__icon">
                    {icon && (
                        <img className="menu-item__icon__img" src={icon} alt="icon"/>  
                    )}
                </div>
                <div className="menu-item__title">{title}</div>
                <div className="menu-item__subtitle">{subtitle}</div>
            </div>
        </div>
    )
}

export default MenuItem