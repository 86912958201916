import { useState } from 'react'
import ImagePreview from '~/components/ImagePreview/ImagePreview'

const IMAGES = [
    "/images/views/luxuryAtParadise/gallery-1.jpg",
    "/images/views/luxuryAtParadise/gallery-2.jpg",
    "/images/views/luxuryAtParadise/gallery-3.jpg",
    "/images/views/luxuryAtParadise/gallery-11.png",
    "/images/views/luxuryAtParadise/gallery-12.png",
    "/images/views/luxuryAtParadise/gallery-6.jpg",
    "/images/views/luxuryAtParadise/gallery-7.jpg",
    "/images/views/luxuryAtParadise/gallery-8.jpg",
    "/images/views/luxuryAtParadise/gallery-9.jpg",
    "/images/views/luxuryAtParadise/gallery-10.jpg",
    "/images/views/luxuryAtParadise/gallery-4.jpg",
    "/images/views/luxuryAtParadise/gallery-5.jpg"
]

const LuxuryAtParadiseGallery = () => {
    const [imagePreviewState, setImagePreviewState] = useState<{ isOpen: boolean, imgUrl: string | null}>({
        isOpen: false,
        imgUrl: null
    })

    const handleImagePreviewOpen = (imageIdx: number) => {
        setImagePreviewState({
            isOpen: true,
            imgUrl: IMAGES[imageIdx]
        })
    }
    const handleImagePreviewClose = () => {
        setImagePreviewState({
            isOpen: false,
            imgUrl: null
        })
    }
    return (
        <div className="luxury-at-paradise-gallery">
            <ImagePreview isOpen={imagePreviewState.isOpen} onClose={handleImagePreviewClose} imgUrl={imagePreviewState.imgUrl}/>
            <div className="luxury-at-paradise-gallery__container">
                {IMAGES.map((imageSrc, idx) => {
                    return (
                        <div className={`luxury-at-paradise-gallery__image-wrapper-${(idx) % 12}`}>
                            <img alt="" src={imageSrc} className="luxury-at-paradise-gallery__image" onClick={() => {
                                handleImagePreviewOpen(idx)
                            }}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default LuxuryAtParadiseGallery