import { useState } from 'react'
import HotelVsSuite from '~/components/hotelVsSuite/HotelVsSuite/HotelVsSuite'
import SuiteDetails from '~/components/hotelVsSuite/SuiteDetails/SuiteDetails'

const HotelVsSuitePage = () => {
    const [screen, setScreen] = useState(0)
    const handleDetailsClick = () => {
        setScreen(1)
    }
    if(screen){
    return(
        <SuiteDetails/>
        )
    }
    return(
       <HotelVsSuite onDetailsClick={handleDetailsClick}/>
    )
}

export default HotelVsSuitePage
