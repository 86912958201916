import React from 'react'
import HomeButton from '~/components/HomeButton/HomeButton'
import BigTimeline from '~/components/BigTimeline/BigTimeline'
import BigTimelineEvent from '~/components/BigTimelineEvent/BigTimelineEvent'
import WhiteLogoHeader from '~/components/WhiteLogoHeader/WhiteLogoHeader'

const PvcTimeLineView = () => {
    const pageRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if(pageRef && pageRef.current){
            pageRef.current.scrollTop = pageRef.current.scrollHeight
        }
    }, [])
    return(
        <div className="pvc-timeline-view" ref={pageRef}>
            <HomeButton/>
            <div className="pvc-timeline-view__content" style={{backgroundImage: "url('/images/views/pvcTimeline/pvcTimelineBg.png')"}}>
                <WhiteLogoHeader isAbsolute/>
                <div className="pvc-timeline-view__timeline">
                    <BigTimeline>
                        <BigTimelineEvent title="???" description="Two Bedroom Ocean Suite in Premium Season" year="2024" left first/>
                        <BigTimelineEvent title="$45,465 USD" description="Two Bedroom Ocean Suite in Premium Season" year="2018"/>
                        <BigTimelineEvent title="$32,056 USD" description="Two Bedroom Ocean Suite in Premium Season" year="2011" left/>
                        <BigTimelineEvent title="$20,450 USD" description="Two Bedroom Ocean Suite in Premium Season" year="2005"/>
                        <BigTimelineEvent title="$12,750 USD" description="Two Bedroom Ocean Suite in Premium Season" year="2001" left/>
                        <BigTimelineEvent title="$10,150 USD" description="Two Bedroom Ocean Suite in Premium Season" year="1997"/>
                        <BigTimelineEvent title="$6,500 USD" description="Two Bedroom Ocean Suite in Premium Season" year="1992" last left/>
                    </BigTimeline>
                    <div className="pvc-timeline-view__timeline__title">PVC Timeline</div>
                </div>
            </div>
        </div>
    )
}

export default PvcTimeLineView
