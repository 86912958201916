import MenuItem from '~/components/MenuItem/MenuItem'
import { VIEWS } from '~/types/ViewTypes'

const Menu = () => {
    return(
        <div className="menu">
            <MenuItem view={VIEWS.ABOUT} title="About" subtitle="Paradise Village" icon="/images/views/menu/items/logo-menu-icon.svg"/>
            <MenuItem view={VIEWS.MAP} title="Paradise Village" subtitle="Map" icon="/images/views/menu/items/map-menu-icon.svg"/>
            <MenuItem view={VIEWS.CALCULATOR} title="Vacation" subtitle="Calculator" icon="/images/views/menu/items/calc-menu-icon.svg"/>
            <MenuItem view={VIEWS.RENTINGVSMEMBERSHIP} title="Renting vs " subtitle="Membership" icon="/images/views/menu/items/house-menu-icon.svg"/>
            <MenuItem view={VIEWS.HOTELVSSUITE} title="Hotel vs" subtitle="Suites" icon="/images/views/menu/items/building-menu-icon.svg"/>
            <MenuItem view={VIEWS.PVCTIMELINE} title="PVC" subtitle="Timeline"/>
            <MenuItem view={VIEWS.INTERVALINTERNATIONAL} title="Interval" subtitle="International" icon="/images/views/menu/items/interval-menu-icon.svg"/>
            <MenuItem view={VIEWS.ADVANTAGEPROGRAM} title="Advantage" subtitle="Program" icon="/images/views/menu/items/advantage-menu-icon.svg"/>
            <MenuItem view={VIEWS.LUXURYATPARADISE} title="Luxury at" subtitle="Paradise"/>
        </div>
    )
}
export default Menu