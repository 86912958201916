import React from 'react'

interface Props  {
    title: string,
    description: string,
    year: string,
    first?: boolean, 
    last?: boolean,
    left?: boolean
}

const BigTimelineEvent = (
    { 
        title,
        description,
        year,
        first, 
        last,
        left
    }: Props) => {
    return(
        <div className="big-timeline-event">
            { !first && (<div className="big-timeline-event__line"></div>)}
            <div className={`big-timeline-event__content ${left ? 'big-timeline-event__content--left' : ''}`}>
                <div className="big-timeline-event__year">{year}</div>
                <div className="big-timeline-event__bubble">
                    <div className="big-timeline-event__title">{title}</div>
                    <div className="big-timeline-event__description">{description}</div>
                </div>
            </div>
            { !last && (<div className="big-timeline-event__line"></div>)}
        </div>
    )
}

export default BigTimelineEvent