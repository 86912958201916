interface Props { 
    children: React.ReactNode, 
    width?: string, 
    margin?: string, 
    onClick?: Function
}

const TextButton = ({ 
    children, 
    width="100%", 
    margin='0', 
    onClick 
}: Props) => {

    const handleClick = () => {
        if(typeof onClick == 'function'){
            onClick()
        }
    }
    return(
        <div className="text-button" style={{width, margin}}onClick={handleClick}>{children}</div>
    )
}

export default TextButton