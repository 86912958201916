import React, { useRef } from 'react'

interface Props {
    isOpen: boolean
    imgUrl: string | null
    onClose: () => void
}

const ImagePreview = ({ isOpen, imgUrl, onClose}: Props) => {
    const imageRef = useRef<HTMLImageElement>(null)
    
    const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if(imageRef.current && event.target instanceof Node && !imageRef.current.contains(event.target)) onClose()
    }

    if(!isOpen || !imgUrl) return null
    return (
        <div className="image-preview" onClick={handleClose}>
            <div className="image-preview__content-container">
                <img className="image-preview__close-icon" src="images/icons/cross-icon.svg" alt="close"/>
                <img ref={imageRef} className="image-preview__image" src={imgUrl} alt=""/>
            </div>
        </div>
    )
}

export default ImagePreview