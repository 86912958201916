import React from 'react'
import HomeButton from '~/components/HomeButton/HomeButton'
import TextButton from '~/components/TextButton/TextButton'
import ColoredLogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'

interface Props {
    isVisible?: boolean 
    cost: number | null
    onReset?: Function
    onPrintClick?: Function
}

const CalculatorResponse = ({isVisible = true, cost, onReset, onPrintClick}: Props) => {
    const handleReset = () => {
        if(onReset){
            onReset();
        }
    }

    if(!isVisible) return null
    return(
        <div className="calculator-response">
            <HomeButton/>
            <div className="calculator-response__background-1">
                <img src="/images/pages/vacational-calculator/calculator-bg-1.png" alt=""/>
            </div>
            <div className="calculator-response__background-2">
                <img src="/images/pages/vacational-calculator/calculator-bg-2.png" alt=""/>
            </div>
            <ColoredLogoHeader/>
            <div className="calculator-response__content">
                <div className="calculator-response__title">Vacation cost</div>
                <div className="calculator-response__value">$ {cost} USD</div>
                <div className="calculator-response__subtitle">How much you will spend on vacations anyway?</div>
                <TextButton width="35vw" margin="2vw auto 0 auto" onClick={handleReset}>Calculate again</TextButton>
                <TextButton width="35vw" margin="2vw auto 0 auto" onClick={onPrintClick}>Print Report</TextButton>
            </div>
        </div>
    )
}

export default CalculatorResponse