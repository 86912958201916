import HomeButton from "~/components/HomeButton/HomeButton"
import IconButton from "~/components/IconButton/IconButton"
import WhiteLogoHeader from "~/components/WhiteLogoHeader/WhiteLogoHeader"

interface Props {
    onZoomOut: () => void
}
const MapZoom3 = ({onZoomOut}: Props) => {
    return (
        <div className="map-zoom-3">
            <WhiteLogoHeader isAbsolute/>
            <img alt="" className="map-zoom-3__background" src="/images/views/map/map-zoom-3-2.jpg"/>
            <div className="map-zoom-3__content">
                <div className="map-zoom-3__title">Travel around the World</div>
                <div className="map-zoom-3__controls">
                    <IconButton icon="SHOW-LESS" color="orange" onClick={onZoomOut}/>
                    <IconButton icon="SHOW-MORE" color="gray"/>
                </div>
            </div>
            <HomeButton/>
            
        </div>
    )
}

export default MapZoom3