import HomeButton from "~/components/HomeButton/HomeButton"
import IconButton from "~/components/IconButton/IconButton"
import WhiteLogoHeader from "~/components/WhiteLogoHeader/WhiteLogoHeader"
interface Props {
    onZoomIn: () => void
    onZoomOut: () => void
}
const MapZoom2 = ({ onZoomIn, onZoomOut }: Props) => {
    return (
        <div className="map-zoom-2">
            <WhiteLogoHeader isAbsolute/>
            <img className="map-zoom-2__background" src="/images/views/map/map-zoom-2-2.jpg" alt=""/>
            <div className="map-zoom-2__content">
                <div className="map-zoom-2__title">Living the Americas</div>
                <div className="map-zoom-2__controls">
                    <IconButton icon="SHOW-LESS" color="orange" onClick={onZoomOut}/>
                    <IconButton icon="SHOW-MORE" color="orange" onClick={onZoomIn}/>
                </div>
            </div>
            <HomeButton/>
        </div>
    )
}

export default MapZoom2