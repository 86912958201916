export const enum VIEWS {
    INITIAL = 0,
    MENU = 1,
    ABOUT = 2,
    MAP = 3,
    CALCULATOR = 4,
    RENTINGVSMEMBERSHIP = 5,
    HOTELVSSUITE = 6,
    PVCTIMELINE = 7,
    INTERVALINTERNATIONAL = 8,
    ADVANTAGEPROGRAM = 9,
    LUXURYATPARADISE = 10
}

export type ViewsType = VIEWS