import React from 'react'

const BigTimeline = ({ children }: { children: React.ReactNode }) => {
    return(
        <div className="big-timeline">
            { children }
        </div>
    )
}

export default BigTimeline