import React from 'react'
import WhiteLogoHeader from "~/components/WhiteLogoHeader/WhiteLogoHeader"
import ColoredLogoHeader from "~/components/ColoredLogoHeader/ColoredLogoHeader"
import HomeButton from '~/components/HomeButton/HomeButton'
import TimelineEvent from '~/components/TimelineEvent/TimelineEvent'
import IconButton from '~/components/IconButton/IconButton'

const AboutApp = () => {
    const [ activeSection, setActiveSection ] = React.useState(1)
    const [ dirty, setDirty ] = React.useState(false)
    React.useEffect(() => {
        if(activeSection === 2){
            setDirty(true)
        }
    }, [ activeSection ])

    return(
        <div className="about-view">
            <HomeButton/>
            <div className="about-view__container" attr-active-section={activeSection} attr-dirty={(dirty ? 'true' : 'false')}>
                <div className="about-view__section-1">
                    <div className="about-view__section-1__header">
                        <div className="about-view__section-1__title">The story began at 90’s</div>
                        <div className="about-view__section-1__subtitle-section">
                            <div className="about-view__section-1__subtitle-section__subtitle">Was started by Graziano Sovernigo</div>
                            <div className="about-view__section-1__subtitle-section__image">
                                <img className="about-view__section-1__subtitle-section__image__img" src="/images/views/about/graziano.jpg" alt="graziano"/>
                                <div className="about-view__section-1__subtitle-section__shadow"/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <TimelineEvent first>Today, the development extends over 1,087 acres.</TimelineEvent>
                        <TimelineEvent>The resort presents the perfect combination of ancient Mayan civilization in its design style, with built-in modern comforts.</TimelineEvent>
                        <TimelineEvent>Paradise Village Beach Resort & Spa has become a vacation destination like no other.</TimelineEvent>
                        <TimelineEvent last>Paradise Village Group also offers for sale and manages 545 condominiums and villas.</TimelineEvent>
                    </div>
                    <div className="about-view__section-1__show-more-button">
                        <IconButton icon="SHOW-MORE" onClick={ () => { setActiveSection(2)} }/> 
                    </div>
                </div>
                <div className="about-view__divider" style={{backgroundImage: "url('/images/views/initial/initial-bg.png')"}}>
                    <div className="about-view__divider-header">
                        <WhiteLogoHeader position='LEFT'/>
                    </div>
                </div>
                <div className="about-view__section-2">
                    <ColoredLogoHeader/>
                    <div className="about-view__section-2-content">
                        <div>
                            <TimelineEvent first>The El Tigre Golf and Country Club.</TimelineEvent>
                            <TimelineEvent>The Paradise Village Marina.</TimelineEvent>
                            <TimelineEvent>765-suites and 7 restaurants.</TimelineEvent>
                            <TimelineEvent>An 800-person capacity Convention Center.</TimelineEvent>
                            <TimelineEvent>European spa and fitness center.</TimelineEvent>
                            <TimelineEvent>Shopping mall that includes 110 stores +.</TimelineEvent>
                            <TimelineEvent>A 300,000 square foot business center.</TimelineEvent>
                            <TimelineEvent last>Fully equipped medical center and hospital.</TimelineEvent>
                        </div>
                        <div className="about-view__section-2__show-less-button">
                            <IconButton icon="SHOW-LESS" onClick={ () => { setActiveSection(1)} }/> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutApp