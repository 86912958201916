import React from 'react'
import SelectionButton from '~/components/SelectionButton/SelectionButton'
import HomeButton from '~/components/HomeButton/HomeButton'
import TextButton from '~/components/TextButton/TextButton'
import LogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'
import { CALCULATOR_NIGHTS, CALCULATOR_COST_PER_NIGHT, CALCULATOR_YEARS, CALCULATOR_INFLATION } from '~/constants/constants'
 
interface Props {
    isVisible: boolean
    selectedNights: number
    onSelectedNightsChange: Function
    selectedCostPerNight: number
    onSelectedCostPerNightChange: Function
    selectedYear: number
    onSelectedYearChange: Function
    selectedInflation: number
    onSelectedInflationChange: Function
    onCalculate: Function
}
const Calculator = ({ 
    isVisible = true,
    selectedNights, 
    onSelectedNightsChange, 
    selectedCostPerNight, 
    onSelectedCostPerNightChange,
    selectedYear,
    onSelectedYearChange,
    selectedInflation,
    onSelectedInflationChange,
    onCalculate
}: Props) => {
    const handleCalculate = () => {
        if(typeof onCalculate === 'function'){
            let total = 0;
            let previousValue = 0
            for(let i = 0; i < CALCULATOR_YEARS[selectedYear]; i++){
                if(i === 0){
                    const yearCost = CALCULATOR_COST_PER_NIGHT[selectedCostPerNight]*CALCULATOR_NIGHTS[selectedNights]
                    previousValue = yearCost
                    total += yearCost
                }else{
                    const yearCost = previousValue * (100+CALCULATOR_INFLATION[selectedInflation]) / 100
                    previousValue = yearCost
                    total += yearCost
                }
            }
            onCalculate((Math.ceil(total)).toLocaleString());
        }
    }
    if(!isVisible) return null
    return(
        <div className="calculator">
            <HomeButton/>
            <div className="calculator__background">
                <img className="calculator__background__img" src="/images/pages/vacational-calculator/calculator-bg-1.png" alt=""/>
            </div>
            <LogoHeader/>
            <div className="calculator__title">Calculate</div>
            <div className="calculator__subtitle">Your vacation cost</div>
            <div className="calculator__content">
                <div className="calculator__section">
                    <div className="calculator__section__header">
                        <div className="calculator__section__instruction">Select</div>
                        <div className="calculator__section__title">
                            <div className="calculator__section__title__spacer"></div>
                            <div className="calculator__section__title__text">How many days do you vacation a year?</div>
                            <div className="calculator__section__title__spacer"></div>
                        </div>
                    </div>
                    <div className="calculator__section__controls">
                        { CALCULATOR_NIGHTS.map((e, i) => {
                            return(
                                <SelectionButton key={`nights-${i}`} title={e.toString()} subtitle="Days" selected={i === selectedNights} onClick={() => {onSelectedNightsChange(i)}}/>
                            )
                        })}
                    </div>
                </div>
                <div className="calculator__section">
                    <div className="calculator__section__header">
                        <div className="calculator__section__instruction">Select</div>
                        <div className="calculator__section__title">
                            <div className="calculator__section__title__spacer"></div>
                            <div className="calculator__section__title__text">Average cost per night</div>
                            <div className="calculator__section__title__spacer"></div>
                        </div>
                    </div>
                    <div className="calculator__section__controls">
                        { CALCULATOR_COST_PER_NIGHT.map((e, i) => {
                            return(
                                <SelectionButton key={`cost-${i}`} title={`$ ${e}`} subtitle="USD" selected={i === selectedCostPerNight} onClick={() => {onSelectedCostPerNightChange(i)}}/>
                            )
                        })}
                    </div>
                </div>
                <div className="calculator__section">
                    <div className="calculator__section__header">
                        <div className="calculator__section__instruction">Select</div>
                        <div className="calculator__section__title">
                            <div className="calculator__section__title__spacer"></div>
                            <div className="calculator__section__title__text">How many years you plan on vacationing</div>
                            <div className="calculator__section__title__spacer"></div>
                        </div>
                    </div>
                    <div className="calculator__section__controls">
                        { CALCULATOR_YEARS.map((e, i) => {
                            return(
                                <SelectionButton key={`year-${i}`} title={`${e}`} subtitle="Years" selected={i === selectedYear} onClick={() => {onSelectedYearChange(i)}}/>
                            )
                        })}
                    </div>
                </div>
                <div className="calculator__section">
                    <div className="calculator__section__header">
                        <div className="calculator__section__instruction">Select</div>
                        <div className="calculator__section__title">
                            <div className="calculator__section__title__spacer"></div>
                            <div className="calculator__section__title__text">Rate of inflation you believe will occur</div>
                            <div className="calculator__section__title__spacer"></div>
                        </div>
                    </div>
                    <div className="calculator__section__controls">
                        { CALCULATOR_INFLATION.map((e, i) => {
                            return(
                                <SelectionButton key={`inflation-${i}`} title={`${e} %`} selected={i === selectedInflation} onClick={() => {onSelectedInflationChange(i)}}/>
                            )
                        })}
                    </div>
                </div>
                <TextButton width="35vw" margin="0 auto" onClick={handleCalculate}>Calculate</TextButton>
            </div>
        </div>
    )
}

export default Calculator