import HomeButton from "~/components/HomeButton/HomeButton"
import IconButton from "~/components/IconButton/IconButton"
import WhiteLogoHeader from "~/components/WhiteLogoHeader/WhiteLogoHeader"
import TimelineEvent from '~/components/TimelineEvent/TimelineEvent'

interface Props {
    onZoomIn: () => void
}

const MapZoom1 = ({ onZoomIn }: Props ) => {
    return (
            <div className="map-zoom-1">
                <WhiteLogoHeader isAbsolute/>
                <div className="map-zoom-1__bullets">
                    <TimelineEvent first color="yellow">PRIVATE BEACH</TimelineEvent>
                    <TimelineEvent color="yellow">3 SWIMMING POOLS</TimelineEvent>
                    <TimelineEvent color="yellow">GYM</TimelineEvent>
                    <TimelineEvent color="yellow">SPA</TimelineEvent>
                    <TimelineEvent color="yellow">SPORT CLUB</TimelineEvent>
                    <TimelineEvent color="yellow">GOLF CLUB</TimelineEvent>
                    <TimelineEvent color="yellow">KIDS CLUB</TimelineEvent>
                    <TimelineEvent color="yellow">TENNIS COURTS</TimelineEvent>
                    <TimelineEvent last color="yellow">SKY BAR</TimelineEvent>
                </div>
                <img alt="" className="map-zoom-1__background" src="/images/views/map/map-zoom-1-1.jpg"/>
                <div className="map-zoom-1__content">
                    <div className="map-zoom-1__title">Paradise Village Map</div>
                    <div className="map-zoom-1__controls">
                        <IconButton icon="SHOW-LESS" color="gray"/>
                        <IconButton icon="SHOW-MORE" color="orange" onClick={onZoomIn}/>
                    </div>
                </div>
                <HomeButton/>
                
            </div>
    )
}

export default MapZoom1