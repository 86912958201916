import useViewRouter from '~/hooks/useViewRouter'
import { VIEWS } from '~/types/ViewTypes'

interface Props {
    position?: 'RIGHT' | 'LEFT'
    isAbsolute?: boolean
}

const WhiteLogoHeader = ({position = 'RIGHT', isAbsolute=false}: Props) => {
    const viewRouter = useViewRouter()
    const handleLogoClick = () => {
        viewRouter.push(VIEWS.MENU)
    }
    const containerClassName = `white-logo-header ${position === "RIGHT" ? 'white-logo-header--right' : 'white-logo-header--left"'} ${isAbsolute ? 'white-logo-header--absolute' : ''}`
    return(
        <div className={containerClassName}>
            <img className="white-logo-header__logo" src="/images/logos/logo-white.svg" onClick={handleLogoClick} alt="logo"/>
        </div>
    )
}

export default WhiteLogoHeader