import React from 'react'
import { viewContext } from '~/contexts/viewContext'
import { ViewsType } from '~/types/ViewTypes'

const useViewRouter = () => {
    const context = React.useContext(viewContext)
    const setCurrentPage = context[1]
    return {
        push: (view: ViewsType) => {
            setCurrentPage(view)
        }
    }
}

export default useViewRouter