import React from 'react'
import { useReactToPrint } from 'react-to-print';
import Calculator from '~/components/calculator/Calculator/Calculator'
import CalculatorResponse from '~/components/calculator/CalculatorResponse/CalculatorResponse'
import CalculatorPrintTemplate from '~/components/calculator/CalculatorPrintTemplate/CalculatorPrintTemplate'

const CalculatorView = () => {
    const [ selectedNights, setSelectedNights ] = React.useState(0)
    const [ selectedCostPerNight, setSelectedCostPerNight ] = React.useState(0)
    const [ selectedYear, setSelectedYear ] = React.useState(0)
    const [ selectedInflation, setSelectedInflation ] = React.useState(0)
    const [cost, setCost] = React.useState<number | null>(null)
    const printTemplateRef = React.useRef<HTMLDivElement>(null);
    
    const handleCalculate = (cost: number) => {
        setCost(cost);
    }
    const handleReset = () => {
        setSelectedNights(0)
        setSelectedCostPerNight(0)
        setSelectedYear(0)
        setSelectedInflation(0)
        setCost(null);
    }
    const handlePrintReset = useReactToPrint({
        content: () => printTemplateRef.current,
    });

    return(
        <>
            <CalculatorPrintTemplate 
                ref={printTemplateRef}
                costPerNight={selectedCostPerNight}
                years={selectedYear}
                inflation={selectedInflation}
                cost={cost}
            />
            <Calculator
                isVisible={cost === null}
                selectedNights={selectedNights}
                onSelectedNightsChange={setSelectedNights}
                selectedCostPerNight={selectedCostPerNight}
                onSelectedCostPerNightChange={setSelectedCostPerNight}
                selectedYear={selectedYear}
                onSelectedYearChange={setSelectedYear}
                selectedInflation={selectedInflation}
                onSelectedInflationChange={setSelectedInflation}
                onCalculate={handleCalculate}
            />
            <CalculatorResponse isVisible={cost !== null} cost={cost} onReset={handleReset} onPrintClick={handlePrintReset}/>
        </>
    )
}

export default CalculatorView
