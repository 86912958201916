import HomeButton from '~/components/HomeButton/HomeButton'
import ColoredLogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'

const AdvantageProgramView = () => {
    return(
        <div>
            <HomeButton/>
            <ColoredLogoHeader position="RIGHT" withBackground isAbsolute/>
            <div className="advantage-program-view__logo">
                <img alt="" className="advantage-program-view__logo__img" src="/images/views/advantageProgram/advantage-logo.svg"/>
            </div>
            <div className="advantage-program-view__content">
                <img alt="" className="advantage-program-view__content__image" src="/images/views/advantageProgram/advantage-agosto-2.jpg"/>
            </div>
        </div>
    )
}

export default AdvantageProgramView