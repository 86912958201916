import HomeButton from '~/components/HomeButton/HomeButton'
import ColoredLogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'
import LuxuryAtParadiseGallery from '~/components/LuxuryAtParadiseGallery/LuxuryAtParadiseGallery'

const LuxuryAtParadiseView = () => {
    return(
        <div className="luxury-at-paradise-view">
            <HomeButton/>
            <ColoredLogoHeader/>
            <div className="luxury-at-paradise-view__content">
                <div className="luxury-at-paradise-view__title">Luxury At Paradise</div>
                <LuxuryAtParadiseGallery/>
            </div>
        </div>
    )
}

export default LuxuryAtParadiseView