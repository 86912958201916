import React from 'react'
import LoadingView from '~/views/LoadingView/LoadingView'
import InitialView from '~/views/InitialView/InitialView'
import AboutView from '~/views/AboutView/AboutView'
import MapView from '~/views/MapView/MapView'
import CalculatorView from '~/views/CalculatorView/CalculatorView'
import RentingVsMembershipView from '~/views/RentingVsMembershipView/RentingVsMembershipView'
import HotelVsSuiteView from '~/views/HotelVsSuiteView/HotelVsSuiteView'
import PvcTimelineView from '~/views/PvcTimelineView/PvcTimelineView'
import IntervalInternationalView from '~/views/IntervalInternationalView/IntervalInternationalView'
import AdvantageProgramView from '~/views/AdvantageProgramView/AdvantageProgramView'
import LuxuryAtParadiseView from '~/views/LuxuryAtParadiseView/LuxuryAtParadiseView'
import MenuView from '~/views/MenuView/MenuView'
import { VIEWS, ViewsType } from '~/types/ViewTypes'
import useIsMounted from '~/hooks/useIsMounted'
import { viewContext } from '~/contexts/viewContext'
import useViewRouter from '~/hooks/useViewRouter'


const MINUTE_IN_MILLISECONDS = 60000
const TEN_MINUTES_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 10

const MainPage = () => {
    const [ currentView ] = React.useContext(viewContext)
    const [frontView, setFrontView] = React.useState<ViewsType>(currentView)
    const [isLoadingWindowOpen, setIsLoadingWindowOpen] = React.useState(false);
    const loadingFadeInTime = 1000
    const loadingSlideOutDelayTime = 1000
    const loadingSlideOutTime = 1000
    const isMounted = useIsMounted()
    const viewRouter = useViewRouter()
    const frontViewTimeoutRef = React.useRef<NodeJS.Timeout>()
    const loadingWindowTimeoutRef = React.useRef<NodeJS.Timeout>()
    const initialWindowTimeoutRef = React.useRef<NodeJS.Timeout>()

    React.useEffect(() => {
        if(isMounted){
            setIsLoadingWindowOpen(true)
            const loadingEffectTime = loadingSlideOutDelayTime + loadingSlideOutTime
            frontViewTimeoutRef.current = setTimeout(() => {
                setFrontView(currentView)
            }, loadingFadeInTime)
            loadingWindowTimeoutRef.current = setTimeout(() => {
                setIsLoadingWindowOpen(false)
            }, loadingEffectTime)
            initialWindowTimeoutRef.current = setTimeout(() => {
                viewRouter.push(VIEWS.INITIAL)
            }, TEN_MINUTES_IN_MILLISECONDS)
        }
        return () => {
            if(frontViewTimeoutRef.current) clearTimeout(frontViewTimeoutRef.current)
            if(loadingWindowTimeoutRef.current) clearTimeout(loadingWindowTimeoutRef.current)
            if(initialWindowTimeoutRef.current) clearTimeout(initialWindowTimeoutRef.current)
        }
    }, [currentView, isMounted])

    const loadView = (view: ViewsType) => {
        switch(view){
            case VIEWS.INITIAL:
                return <InitialView/>
            case VIEWS.MENU:
                return <MenuView/>
            case VIEWS.ABOUT:
                return <AboutView/>
            case VIEWS.MAP:
                return <MapView/>
            case VIEWS.CALCULATOR:
                return <CalculatorView/>
            case VIEWS.RENTINGVSMEMBERSHIP:
                return <RentingVsMembershipView/>
            case VIEWS.HOTELVSSUITE:
                return <HotelVsSuiteView/>
            case VIEWS.PVCTIMELINE:
                return <PvcTimelineView/>
            case VIEWS.INTERVALINTERNATIONAL:
                return <IntervalInternationalView/>
            case VIEWS.ADVANTAGEPROGRAM:
                return <AdvantageProgramView/>
            case VIEWS.LUXURYATPARADISE:
                return <LuxuryAtParadiseView/>
            default: 
                return <InitialView/>
        }
    }
    return(
        <div className="view-controller">
            { isLoadingWindowOpen && 
                <div className="view-controller__loading-view-container">
                    <LoadingView view={currentView}/>
                </div>
            }
            <div>
            { loadView(frontView) }
            </div>        
        </div>
    )
}

export default MainPage