import React, { Dispatch, SetStateAction } from 'react';
import { VIEWS, ViewsType } from '~/types/ViewTypes'

interface Props {
    children: React.ReactNode
}
const viewContext = React.createContext<[ViewsType, Dispatch<SetStateAction<ViewsType>>]>([VIEWS.INITIAL, () => {}]);

const ViewContextProvider = ({children} : Props) => {
    const [currentView, setCurrentView] = React.useState<ViewsType>(VIEWS.INITIAL);
    return (
        <viewContext.Provider value={[currentView, setCurrentView]}>
            {children}
        </viewContext.Provider>
    )
}

export {viewContext , ViewContextProvider}