import { VIEWS, ViewsType } from '~/types/ViewTypes'

const LoadingView = ({ view }: {view: ViewsType,}) => {

    const getLoadingImg = (view: ViewsType) => {
        switch(view){
            case VIEWS.INITIAL:
                return "/images/views/loading/loading-text.svg"
            case VIEWS.MENU:
                return "/images/views/loading/loading-text.svg"
            case VIEWS.ABOUT:
                return "/images/views/loading/about-text.svg"
            case VIEWS.MAP:
                return "/images/views/loading/loading-text.svg"
            case VIEWS.CALCULATOR:
                return "/images/views/loading/vacation-calculator-text.svg"
            case VIEWS.RENTINGVSMEMBERSHIP:
                return "/images/views/loading/renting-vs-membership-text.svg"
            case VIEWS.HOTELVSSUITE:
                return "/images/views/loading/hotel-vs-suite-text.svg"
            case VIEWS.PVCTIMELINE:
                return "/images/views/loading/pvc-timeline-text.svg"
            case VIEWS.INTERVALINTERNATIONAL:
                return "/images/views/loading/interval-international-text.svg"
            case VIEWS.ADVANTAGEPROGRAM:
                return "/images/views/loading/advantage-program-text.svg"
            case VIEWS.LUXURYATPARADISE:
                return "/images/views/loading/loading-text.svg"
        }
    }
    return (
        <div className="loading-view fade-in-slide-out">
            <img className="loading-view__image" src={getLoadingImg(view)} alt="screen name"/>
        </div>
    )
}

export default LoadingView