import HomeButton from '~/components/HomeButton/HomeButton'
import ColoredLogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'
const SuiteDetails = () => {
    return (
        <div className="suite-details">
        <HomeButton/>
        <ColoredLogoHeader/>
        <div className="suite-details__content">
            <div className="suite-details__header">
                <div className="suite-details__header-title">Modern, spacious and comfortable suites</div>
                <div className="suite-details__header-subtitle">Your From Away From Home</div>
            </div>
            <div className="suite-details__section">
                <img className='suite-details__section-img' src="/images/views/hotelVsSuite/bedroom.jpg" alt=""/>
                <div className="suite-details__section-content">
                    <img className="suite-details__section-icon" src="/images/views/hotelVsSuite/bed.svg" alt=""/>
                    <div className="suite-details__section-description">Master Bedroom with Balcony and Flat screen TV</div>
                    <div className="suite-details__section-separator"/>
                </div>
            </div>
            <div className="suite-details__section">
                <img className='suite-details__section-img' src="/images/views/hotelVsSuite/living.jpg" alt=""/>
                <div className="suite-details__section-content">
                    <img className="suite-details__section-icon" src="/images/views/hotelVsSuite/tv.svg" alt=""/>
                    <div className="suite-details__section-description">Living and Dining Area</div>
                    <div className="suite-details__section-separator"/>
                </div>
            </div>
            <div className="suite-details__section">
                <img className='suite-details__section-img' src="/images/views/hotelVsSuite/kitchen.jpg" alt=""/>
                <div className="suite-details__section-content">
                    <img className="suite-details__section-icon" src="/images/views/hotelVsSuite/fork.svg" alt=""/>
                    <div className="suite-details__section-description">Fully Equipped Kitchens</div>
                    <div className="suite-details__section-separator"/>
                </div>
            </div>
            <div className="suite-details__section">
                <img className='suite-details__section-img' src="/images/views/hotelVsSuite/bath.jpg" alt=""/>
                <div className="suite-details__section-content">
                    <img className="suite-details__section-icon" src="/images/views/hotelVsSuite/shower.svg" alt=""/>
                    <div className="suite-details__section-description">Bathrooms with Soaking Tubs</div>
                    <div className="suite-details__section-separator"/>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SuiteDetails