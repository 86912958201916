interface Props { 
    title: string, 
    subtitle?: string, 
    selected?: boolean,
    margin?: string,
    onClick?: Function
}

const SelectionButton = ({ 
    title, 
    subtitle, 
    selected = false,
    margin = '0 1vw',
    onClick
}: Props) => {
    const className = `selection-button ${selected ? 'selection-button--selected' : ''} ${!subtitle ? `selection-button--only-title` : ''}`
    const handleClick = () => {
        if(typeof onClick == 'function'){
            onClick()
        }
    }
    return(
        <div className={className} style={{margin}} onClick={handleClick}>
            <div className="selection-button__title">{title}</div>
            <div className="selection-button__subtitle">{subtitle}</div>
        </div>
    )
}

export default SelectionButton