import React from 'react'

interface Props {
    children: React.ReactNode 
    first?: boolean, 
    last?: boolean
    color?: 'blue' | 'yellow'
}
const TimelineEvent = (
    { 
        children, 
        first, 
        last,
        color='blue'
    }: Props) => {

    return(
        <div className="timeline-event">
            <div className="timeline-event__icon">
                <div className={`timeline-event__icon__line ${( first ? 'timeline-event__icon__line--no-show' : '')}`}></div>
                <div className={`timeline-event__icon__dot ${( color==='yellow' ? 'timeline-event__icon__dot--yellow' : '')}`}></div>
                <div className={`timeline-event__icon__line ${( last ? 'timeline-event__icon__line--no-show' : '')}`} ></div>
            </div>
            <div className={`timeline-event__description ${( color==='yellow' ? 'timeline-event__description--white' : '')}`}>{ children }</div>
        </div>
    )
}
export default TimelineEvent