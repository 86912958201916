import React from 'react';
import ReactDOM from 'react-dom';
import './styles/bundle.scss';
import { ViewContextProvider } from '~/contexts/viewContext'
import ViewController from '~/views/ViewController/ViewController'
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: "https://df4183b3f974420bb6a8d7387131ba48@o4503926108520448.ingest.sentry.io/4505549366362112",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1,
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1
});

ReactDOM.render(<ViewContextProvider><ViewController /></ViewContextProvider>,
  document.getElementById('root')
);
