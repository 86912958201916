import React from 'react'
import MapZoom1 from "~/components/map/MapZoom1/MapZoom1"
import MapZoom2 from "~/components/map/MapZoom2/MapZoom2"
import MapZoom3 from "~/components/map/MapZoom3/MapZoom3"

const MapView = () => {
    const [currentZoom, setCurrentZoom] = React.useState(1)
    const handleZoomIn = () => {
        setCurrentZoom(currentZoom+1);
    }
    const handleZoomOut = () => {
        setCurrentZoom(currentZoom-1);
    }
    if(currentZoom === 1) return <MapZoom1 onZoomIn={handleZoomIn}/>
    if(currentZoom === 2) return <MapZoom2 onZoomIn={handleZoomIn} onZoomOut={handleZoomOut}/>
    if(currentZoom === 3) return <MapZoom3 onZoomOut={handleZoomOut}/>
    return null
}

export default MapView