import React from 'react'
import moment from 'moment'

import LogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'
import { CALCULATOR_COST_PER_NIGHT, CALCULATOR_YEARS, CALCULATOR_INFLATION } from '~/constants/constants' 

interface Props{
    costPerNight: number
    years: number,
    inflation: number,
    cost: number | null
}
const CalculatorPrintTemplate = React.forwardRef(({costPerNight, years, inflation, cost}: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const date = moment().format('ll')
    return (
        <div className="calculator-print-template">
            <div className="calculator-print-template__container" ref={ref}>
                <div className="calculator-print-template__header">
                    <img className="calculator-print-template__header-logo" src="/images/logos/logo-color.png" alt="logo"/>
                    <div className="calculator-print-template__header-date">{date}</div>
                </div>
                <div className="calculator-print-template__title">Our projected future vacation lodging cost</div>
                <div className="calculator-print-template__sections">
                    <Section title="Your preferred average lodging cost per night" value={`$ ${CALCULATOR_COST_PER_NIGHT[costPerNight]} USD`}/>
                    <Section title="How many years you plan on vacationing?" value={`${CALCULATOR_YEARS[years]} Years`}/>
                    <Section title="My projected rate of inflation" value={`${CALCULATOR_INFLATION[inflation]}%`}/>
                </div>
                <div className="calculator-print-template__summary">
                    <div className="calculator-print-template__summary__title">Your projected future vacation lodging cost is</div>
                    <div className="calculator-print-template__summary__subtitle">{`${cost} USD`}</div>
                </div>
            </div>
        </div>
    )
})

const Section = ({ title, value }: { title: string, value: string }) => {
    return(
        <div className="calculator-print-template__section">
            <div className="calculator-print-template__section__title">{title}</div>
            <div className="calculator-print-template__section__value">{value}</div>
        </div>
    )
}
export default CalculatorPrintTemplate