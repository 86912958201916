import ColoredLogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'
import Menu from '~/components/Menu/Menu'

const MenuView = () => {
    return (
            <div className="menu-view__background" style={{backgroundImage: "url('/images/views/menu/menu-bg.png')"}}>
                <ColoredLogoHeader position="LEFT"/>
                <div className="menu-view__container">
                    <Menu/>
                </div>
            </div>
    )
}

export default MenuView