import HomeButton from '~/components/HomeButton/HomeButton'
import WhiteLogoHeader from '~/components/WhiteLogoHeader/WhiteLogoHeader'

const RentingVsMembershipView = () => {
    return(
        <>
            <div className="renting-vs-memership-view">
                <HomeButton/>
                <img className="renting-vs-memership-view__palm-img" src="/images/views/rentingVsMembership/palm.png" alt=""/>
                <img className="renting-vs-memership-view__fern-img" src="/images/views/rentingVsMembership/fern.png" alt=""/>
                <div className="renting-vs-memership-view__section-1" style={{backgroundImage: "url('/images/views/rentingVsMembership/beach-bg.png')"}}>
                    <WhiteLogoHeader/>
                    <div className="renting-vs-memership-view__section-1__title">Membership Benefits</div>
                    <div className="renting-vs-memership-view__section-1__text-section">
                        <div className="renting-vs-memership-view__section-1__text-section__title">Ultra-flexible vacation </div>
                        <div className="renting-vs-memership-view__section-1__text-section__description">
                            <ul>
                                <li>Points system for every budget.</li>
                                <li>Member choose their preferred suite size, time of year and number of nights.</li>
                                <li>and much more...</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="renting-vs-memership-view__section-2">
                    <div className="renting-vs-memership-view__section-2__fact-1">
                        <div className="renting-vs-memership-view__section-2__promo-tag">20% OFF</div>
                        <div className="renting-vs-memership-view__section-2__fact-1__title">Food & Beverage Fun Local Activities</div>
                    </div>
                    <div className="renting-vs-memership-view__section-2__fact-2">
                        <div className="renting-vs-memership-view__section-2__fact-2__title">El Tigre Green Fees</div>
                        <div className="renting-vs-memership-view__section-2__promo-tag">50% OFF</div>
                    </div>
                     <div className="renting-vs-memership-view__section-2__fact-3">
                        <div className="renting-vs-memership-view__section-2__fact-3__title">Complementary Access</div>
                        <ul className="renting-vs-memership-view__section-2__list">
                            <li className="renting-vs-memership-view__section-2__list__item">Sports & Raquet Club</li>
                            <li className="renting-vs-memership-view__section-2__list__item">Gym & Spa</li>
                            <li className="renting-vs-memership-view__section-2__list__item">Beach Club</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>   
    )
}

export default RentingVsMembershipView
