import useViewRouter from '~/hooks/useViewRouter'
import { VIEWS } from '~/types/ViewTypes'

const HomeButton = () => {
    const viewRouter = useViewRouter()
    const handleClick = () => {
        viewRouter.push(VIEWS.MENU)
    }
    return(
        <div className="home-button" onClick={handleClick}>
            <img className="home-button__img" src="/images/icons/home-icon.svg" alt="icon"/>
            HOME
        </div>
    )
}

export default HomeButton