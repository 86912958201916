import HomeButton from '~/components/HomeButton/HomeButton'
import ColoredLogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'

const IntervalInternationalView = () => {
    return(
             <div className="interval-international-view">
                <HomeButton/>
                <ColoredLogoHeader isAbsolute withBackground/>
                <div className="interval-international-view__logo">
                    <img alt="" className="interval-international-view__logo__img" src="/images/views/intervalInternational/interval-logo.svg"/>
                </div>
                <div className="interval-international-view__content">
                    <img alt="" className="interval-international-view__content__image" src="/images/views/intervalInternational/interval-agosto-2.jpg"/>
                </div>
            </div>
    )
}

export default IntervalInternationalView