import HomeButton from '~/components/HomeButton/HomeButton'
import ColoredLogoHeader from '~/components/ColoredLogoHeader/ColoredLogoHeader'

const HotelVsSuite = ({ onDetailsClick }: { onDetailsClick: () => void}) => {
    return (
        <div className="hotel-vs-suite">
            <HomeButton/>
            <ColoredLogoHeader/>
            <div className="hotel-vs-suite__background">
                <img className="hotel-vs-suite__background__img" src="/images/views/hotelVsSuite/HotelVsSuiteBg.svg" alt=""/>
            </div>
            <div className="hotel-vs-suite__content">
                <div>
                    <div className="hotel-vs-suite__title">If the cost were the same?</div>
                    <div className="hotel-vs-suite__subtitle">Which Would You Prefer?</div>
                </div>
                <div className="hotel-vs-suite__timeshare-room">
                    <div className="hotel-vs-suite__timeshare-room__image">
                        <img className="hotel-vs-suite__timeshare-room__image__img" src="/images/views/hotelVsSuite/timeshare-suite.svg" alt="suite"/>
                    </div>
                    <div className="hotel-vs-suite__timeshare-room__container">
                        <div className="hotel-vs-suite__timeshare-room__content">
                                <div className="hotel-vs-suite__timeshare-room__title">2 Bedroom Suite</div>
                                <div className="hotel-vs-suite__timeshare-room__subtitle">Your Home Away From Home</div>
                                <div className="hotel-vs-suite__timeshare-room__description">Comfortable Accommodations for 6 Members & Guests</div>
                        </div>
                        <div className="hotel-vs-suite__timeshare-room__details-btn" onClick={onDetailsClick}>See Details <img className="suite-details__section-icon" src="/images/views/hotelVsSuite/arrow.svg" alt=""/></div>
                    </div>
                </div>
                <div className="hotel-vs-suite__hotel-room">
                    <div className="hotel-vs-suite__hotel-room__image">
                        <img className="hotel-vs-suite__hotel-room__image__img" src="/images/views/hotelVsSuite/typical-hotel-room.svg" alt="hotel"/>
                    </div>
                    <div className="hotel-vs-suite__hotel-room__content">
                        <div className="hotel-vs-suite__hotel-room__title">Typical Hotel Room</div>
                        <div className="hotel-vs-suite__hotel-room__subtitle">2 Occupants</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HotelVsSuite