import React from 'react'
import useViewRouter from '~/hooks/useViewRouter'
import { VIEWS } from '~/types/ViewTypes'
const InitialView = () => {

    const viewRouter = useViewRouter()

    const handleClick = () => {
        viewRouter.push(VIEWS.MENU)
    }
    return(
        <div 
          className="initial-view__background" 
          style={{backgroundImage: "url('/images/views/initial/initial-bg.png')"}} 
          onClick={handleClick}
        >
            <div className="initial-view__circle">
                <img className="initial-view__circle__img" src="/images/logos/logo-white.svg" alt="logo"/>
            </div>
            <img className="initial-view__touch-icon" src="/images/views/initial/touch.svg" alt="icon"/>
        </div>
    )
}

export default InitialView
